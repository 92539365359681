:root {
  --decorators-opacityLarge: 0.87;
  --decorators-opacityMedium: 0.38;
  --decorators-opacitySmall: 0.08;
  --decorators-borderSmall: 1px solid;
  --decorators-boxShadowMedium: 0px 1px 2px rgba(0, 0, 0, 0.3);
  --decorators-boxShadowSmall: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  --decorators-borderRadius-small: 8px;
  --decorators-borderRadius-xLarge: 32px;
  --layers-primaryLight: #4068B1;
  --layers-primaryBase: #17469E;
  --layers-primaryBaseOpcMedium: rgba(31, 47, 63, var(--decorators-opacityMedium));
  --layers-primaryBaseOpcSmall: rgba(31, 47, 63, var(--decorators-opacitySmall));
  --layers-primaryDark: #0E2A5F;
  --layers-primary0: #E6EEFB;
  --layers-primary50: #D1DDF2;
  --layers-primary100: #BDCCE8;
  --layers-primary150: #A8BCDF;
  --layers-primary200: #93ABD6;
  --layers-primary300: #6A89C3;
  --layers-primary400: #4068B1;
  --layers-primary500: #17469E;
  --layers-primary600: #12387E;
  --layers-primary700: #0E2A5F;
  --layers-primary800: #091C3F;
  --layers-primary850: #07152F;
  --layers-primary900: #050E20;
  --layers-primary950: #020710;
  --layers-primary1000: #000000;
  --layers-secondaryLight: #FEF1F1;
  --layers-secondaryBase: #ED1C24;
  --layers-secondaryDark: #C4171E;
  --layers-secondary0: #FEF1F1;
  --layers-secondary50: #FCDCDD;
  --layers-secondary100: #FBC6C8;
  --layers-secondary150: #F9B1B4;
  --layers-secondary200: #F79C9F;
  --layers-secondary300: #F47176;
  --layers-secondary400: #F0474D;
  --layers-secondary500: #ED1C24;
  --layers-secondary600: #C4171E;
  --layers-secondary700: #9B1217;
  --layers-secondary800: #730D11;
  --layers-secondary850: #5E0B0E;
  --layers-secondary900: #4A080A;
  --layers-secondary950: #350607;
  --layers-secondary1000: #210304;
  --layers-complementarySuccessDark: #40872b;
  --layers-complementaryWarningDark: #eb8c1c;
  --layers-complementaryDangerDark: #bd2214;
  --layers-complementaryInfoDark: #3e559b;
  --layers-complementarySuccessLight: #DCEFD7;
  --layers-complementaryWarningLight: #F9DDBB ;
  --layers-complementaryDangerLight: #ffebe9;
  --layers-complementaryInfoLight: #B2BBD7;
  --layers-complementaryLinkNotVisited: #3366cc;
  --layers-complementaryLinkVisited: #795cb2;
  --layers-grayscale5: #dddddd;
  --layers-grayscale4: #b0b0b0;
  --layers-grayscale3: #868686;
  --layers-grayscale2: #5e5e5e;
  --layers-grayscale1: #333333;
  --layers-background: #f9f9f9;
  --layers-surface: #ffffff;
  --layers-onPrimary: #ffffff;
  --layers-onSecondary: #ffffff;
  --layers-onDanger: #ffffff;
  --layers-onSuccess: #333333;
  --layers-onWarning: #333333;
  --layers-onSurfaceHighEmph: #333333;
  --layers-onSurfaceHighEmphOpcMedium: rgba(51, 51, 51, var(--decorators-opacityMedium));
  --layers-onSurfaceHighEmphOpcSmall: rgba(51, 51, 51, var(--decorators-opacitySmall));
  --layers-onSurfaceLowEmph: #696969;
  --layers-additionalFavorite: #ff8882;
  --layers-additionalRatings: #ffda77;
  --layers-additionalSuggested: #ffab73;
  --layers-additionalLightBlue: #cad7e5;
  --layers-additionalLightBlueBG: #F4F7FA;
  --typography-fontFamily: "Roboto", sans-serif;
  --typography-heading1: 32px;
  --typography-heading1LineHeight: 40px;
  --typography-heading2: 24px;
  --typography-heading2LineHeight: 32px;
  --typography-heading3: 22px;
  --typography-heading3LineHeight: 26px;
  --typography-heading4: 18px;
  --typography-heading4LineHeight: 20px;
  --typography-heading5: 16px;
  --typography-heading5LineHeight: 20px;
  --typography-subHeading1: 26px;
  --typography-subHeading1LineHeight: 34px;
  --typography-subHeading2: 20px;
  --typography-subHeading2LineHeight: 24px;
  --typography-subHeading3: 16px;
  --typography-subHeading3LineHeight: 20px;
  --typography-body: 14px;
  --typography-bodyLineHeight: 20px;
  --typography-button: 14px;
  --typography-buttonLineHeight: 20px;
  --typography-assistiveText: 12px;
  --typography-assistiveTextLineHeight: 16px;
  --spacing-none: 0px;
  --spacing-micro: 2px;
  --spacing-mini: 4px;
  --spacing-tiny: 8px;
  --spacing-xSmall: 12px;
  --spacing-small: 16px;
  --spacing-medium: 24px;
  --spacing-large: 32px;
  --spacing-huge: 48px;
  --spacing-mega: 64px;
  --spacing-giga: 96px;
  --layers-selectedCategory: var(--layers-additionalSelectedState);
  --layers-sideMenuSelected: var(--layers-primaryBase);
  --layers-categoriesBackground: var(--layers-additionalInfoLight);
  --layers-headerSelector: var(--layers-additionalSelectedState);
  --layers-lightBorder: #EEEEEE;
  --layers-complementaryInfo: #54659C;
  --layers-complementaryDanger: #BD2214;
  --layers-additionalInfoLight: #E6EEFB;
  --layers-offerLabelStickerColor: var(--layers-secondaryDark);
  --layers-offerLabelTextColor:  var(--layers-onPrimary);
  --layers-offerLabelBorder:  var(--spacing-none) solid  var(--layers-secondaryDark);
  --layers-offerLabelRadius:  var(--spacing-small);
  --layers-offerLabelPadding:  var( --spacing-mini);
  --layers-offerLabelPrimaryColor:  var(--layers-secondaryBase);
  --layers-offerLabelSecondaryColor:  var(--layers-surface);
  --layers-GridLabelColor: var(--layers-secondaryLight);
  --layers-GridLabelTextColor:  var(--layers-onSurfaceHighEmph);
  --layers-QsSpace:  var(--spacing-none);
  --layers-CtaRadius:  var(--decorators-borderRadius-small);
  --layers-TileRadius:  var(--decorators-borderRadius-small);
  --layers-LabelSpaceLeft:  var(--spacing-tiny);
  --layers-LabelSpaceTop:  var(--spacing-tiny);
  --layers-TagRadius: var(--decorators-borderRadius-small);
  --layers-TagBg: var(---layers-surface);
  --layers-TileTagSpace: var(--spacing-none);
  --layers-DetailTagSpaceHor: var(--spacing-mini);
  --layers-DetailTagSpaceVert: var(--spacing-none);
  --layers-headerTopRowColor: var(--layers-primaryBase);
  --layers-headerBottomRowColor:  var(--layers-surface);
  --layers-cartBackGroundColor:  var(--layers-background);
  --layers-cartBackGroundHoverColor:  var(--layers-additionalSelectedState);
  --layers-cartBorderColor:  var(--layers-grayscale5);
  --layers-cartHighlightColor:  var(--layers-secondaryBase);
  --layers-sliderFill: var(--layers-primaryBase);
  --space-none: var(--spacing-none);
  --space-XS: var(--spacing-mini);
  --space-SM: var(--spacing-tiny);
  --space-M: var(--spacing-small);
  --space-L: var(--spacing-large);
  --space-XL: var(--spacing-xlarge);
  --space-XLL: var(--spacing-mega);
  --radius-none: var(--spacing-none);
  --radius-XS: var(--spacing-mini);
  --radius-SM: var(--spacing-tiny);
  --radius-M: var(--spacing-small);
  --radius-L: var(--spacing-large);
  --radius-XL: var(--spacing-XLRadius);
  --layers-grayscale6: #F6F6F6;
  --layers-TabRadius: var(--radius-L);
  --layers-ActiveTabFill: var(--layers-additionalSelectedState);
  --layers-InactiveTabFill: var(--layers-surface);
  --layers-InactiveTabStroke: var(--layers-grayscale5);
  --layers-ActiveTabStroke: var(--layers-primaryBase);
  --layers-CtaStrokeFilIResting: var(--layers-primaryBase);
  --layers-quantitySelectorOrderTilecircle: var(--layers-primaryBase);
  --layers-quantitySelectorOrderTileCtaStrokeFilIResting: var(--layers-primaryBase);
  --layers-quantitySelectorBorderColor: var(--layers-additionalSelectedState);
  --layers-quantitySelectorOrderTileCtaRadius: var(--decorators-borderRadius-small);
  --layers-quantitySelectorOrderTileBorderColor: var(--layers-additionalSelectedState);
  --layers-quantitySelectorOrderTileUnselectedTextColor: var(--layers-surface);
  --layers-quantitySelectorOrderTileSelectedTextColor: var(--layers-onSurfaceHighEmph);
  --layers-newsletterTextColor: var(--layers-surface);
  --layers-quantitySelectorOrderTileSelectedIconColor: var(--layers-onSurfaceHighEmph);
  --layers-quantitySelectorOrderTileUnselectedIconColor: var(--layers-surface);
  --layers-radio-option-container-bg-selected: var(--layers-additionalSelectedState);
  --layers-toastBackground: var(--layers-grayscale6);
  --wave-breadcrumb-text-color: var(--layers-complementaryLink);
  --wave-breadcrumb-last-text-color: var(--layers-onSurfaceLowEmph);
  --mask: radial-gradient(1rem at bottom, #0000 97%, #000) 50% / 24px 100%;
  --layers-productTileV2CtaRadius: 100%;
  --layers-offerLabelV2Bg: #B5142C;
  --layers-offerLabelV2FontColor: var(--layers-onPrimary);
  --layers-offerLabelV2Padding: var(--spacing-mini);
  --layers-offerLabelV2Ribbon: #810E1F;
  --layers-offerLabelV2ForYouBg: var(--layers-secondaryLight);
  --layers-offerLabelV2ForYouFontColor: var(--layers-onSurfaceHighEmph);
  --layers-offerLabelV2Radius: var(--spacing-small) var(--spacing-small) var(--spacing-small) var(--spacing-small);
  --layers-offerHighlight: #ffeebf;
  --layers-listBtnBg: var(--layers-surface);
  --typography-headingsFontFamily: Roboto, sans-serif;
  --social-bg: var(--layers-additionalInfoLight);
  --social-border-radius: 50%;
  --layers-complementarySuccess: #52AE37;
  --layers-complementaryWarning: #F8941E;
  --layers-complementaryLink: #3E559B;
  --layers-additionalSelectedState: #D1DDF2;
  --layers-additionalSuccessLight: #EEF7EB;
  --layers-additionalDangerLight: #FCE7E5;
  --layers-additionalWarningLight: #FEF4E9;
  --text-area-border-radius: var(--spacing-tiny);
  --filter-chip-bg-hovered: var(--layers-additionalSelectedState);
  --filter-chip-bg-selected-hovered: var(--layers-additionalSelectedState);
  --layers-productTileQsRadius: var(--radius-L);
  --dialog-width-s: 32rem;
  --dialog-width-m: 44.5rem;
  --dialog-overlay-color: rgb(48, 48, 48, 0.7);
  --dialog-backgroundColor: var(--layers-surface);
  --dialog-borderRadius: var(--decorators-borderRadius-small);
  --stepper-progress-bar-root-bg: var(--layers-grayscale2);
  --stepper-progress-bar-root-bg-secondary: var(--layers-grayscale4);
  --stepper-progress-bar-border-radius: 0;
  --stepper-progress-bar-height: 2px;
  --stepper-progress-bar-height-thick: 4px;
  --stepper-progress-bar-indicator-bg: var(--layers-secondaryBase);
  --stepper-progress-bar-indicator-height: 2px;
  --stepper-progress-bar-indicator-height-thick: 4px;
  --stepper-icon-width: 24px;
  --stepper-icon-fill: var(--layers-surface);
  --stepper-icon-fill-active: var(--layers-primaryBase);
  --stepper-icon-fill-completed: var(--layers-complementarySuccess);
  --stepper-icon-border: var(--layers-grayscale2);
  --stepper-icon-border-secondary-variant: var(--layers-grayscale4);
  --stepper-icon-border-active: var(--layers-primaryBase);
  --stepper-icon-border-width: 2px;
  --stepper-icon-border-width-thick: 4px;
  --stepper-connector-width: 2px;
  --stepper-vertical-step-size: 60px;
  --stepper-connector-color: var(--layers-grayscale2);
  --stepper-connector-color-active: var(--layers-primaryBase);
  --stepper-tab-backgroundColor: var(--layers-grayscale5);
  --stepper-tab-label-color: var(--layers-primaryDark);
  --stepper-label-color: var(--layers-grayscale2);
  --stepper-label-color-active: var(--layers-primaryBase);
  --radio-group-gap: var(--spacing-small);
  --radio-group-label-margin-bottom: 0;
  --radio-group-label-color: var(--layers-primaryDark);
  --toggle-button-width: 48px;
  --toggle-button-height: 48px;
  --toggle-button-radius: 10px;
  --toggle-button-border-color: var(--layers-grayscale3);
  --toggle-button-background-color: rgba(0,0,0,0);
  --toggle-button-color: var(--layers-onSurfaceHighEmph);
  --toggle-button-selected-border-color: var(--layers-primaryBase);
  --toggle-button-selected-background-color: var(--layers-primaryBase);
  --toggle-button-selected-color: var(--layers-onPrimary);
  --toggle-button-hover-background-color: var(--layers-primaryBaseOpcSmall);
  --checkbox-input-width: 24px;
  --checkbox-input-height: 24px;
  --checkbox-input-borderRadius: 4px;
  --checkbox-input-color: var(--layers-primaryBase);
  --checkbox-input-color-selected: var(--layers-primaryBase);
  --checkbox-input-checkmark-color-selected: var(--layers-onPrimary);
  --checkbox-label-color: var(--layers-primaryBase);
  --text-area-paddings: 16px;
  --text-area-disabled-opacity: 0.5px;
  --text-area-placeholder: ;
  --text-area-error-border: 1px solid var(--layers-complementaryDangerLight);
  --text-area-backgroundColor: var(--layers-background);
  --toggle-button-font-size: var(--typography-heading1);
  --toggle-button-line-height: var(--typography-heading1LineHeight);
  --toggle-button-font-family: var(--typography-fontFamily);
  --progress-bar-root-bg: var(--layers-grayscale5);
  --progress-bar-border-radius: var(--decorators-borderRadius-small);
  --progress-bar-height: 12px;
  --progress-bar-indicator-bg: var(--layers-primaryBase);
  --progress-bar-indicator-height: 6px;
  --button-fontWeight: bold;
  --searchbar-container-paddings: var(--spacing-mini) var(--spacing-tiny) var(--spacing-mini) var(--spacing-mini);
  --breadcrumb-text-color: var(--layers-complementaryLink);
}
